// require("./tweenmax3/TweenLite.min.js");
// require("./tweenmax3/TimelineLite.min.js");
// require("./tweenmax3/easing/EasePack.min.js");
// require("./tweenmax3/plugins/CSSPlugin.min.js");
// require("./tweenmax3/plugins/ScrollToPlugin.min.js");



require("./gsap/gsap.min");
require("./gsap/ScrollToPlugin.min");
require("./gsap/EasePack.min");
require("./gsap/CSSRulePlugin.min");