var getString = require("../utils/parseData").getString;

$.fn.uiDialog = function (options) {
    options = $.extend({
        overlayClose: true,
        resize: false,
        modal: true,
        minGap: 15,
        wrapper: false
    }, options);
    return this.each(function () {
        var elm = this;
        var $window = $(window);
        var $document = $(document);
        var dialog = $("<div class='ui-dialog'>");
        var wrapper;
        if (options.modal) {
            var overlay = $('<div class="ui-dialog-overlay">').appendTo("body");
        }

        if ($(elm).data("width")) {
            options.width = parseInt($(elm).data("width"), 10);
        }
        if ($(elm).data("height")) {
            options.height = parseInt($(elm).data("height"), 10);
        }
        if ($(elm).data("grow")) {
            options.grow = true;
        }

        var windowHeight = $(window).height();
        if (window.innerHeight) {
            windowHeight = window.innerHeight;
        }

        // options.modal2 = getString(elm, "modal2", false);

        if (options.modal) {
            overlay.bindTap().on({
                click: function (e) {
                    e.preventDefault();
                    if (options.overlayClose) {
                        $(elm).trigger("dialog.close");
                    } else {
                        dialog.addClass("ui-dialog-deactivate");
                        setTimeout(function () {
                            dialog.removeClass("ui-dialog-deactivate");
                        }, 100);
                    }
                }
            });
        } else {
            dialog.addClass("ui-dialog-float");
        }


//        $("body").css({overflow:"hidden"});
        var width = options.width || Math.floor($window.width() * 0.8);
        var height = options.height || Math.floor(windowHeight * 0.8);

        if ($window.width() < width + options.minGap * 2) {
            width = $window.width() - options.minGap * 2;
        }
        if (windowHeight < height + options.minGap * 2) {
            height = windowHeight - options.minGap * 2;
        }

        var left = ($window.width() - width) / 2;

        var top;

        if (windowHeight > height) {
            top = (windowHeight - height) / 2 + $window.scrollTop();
        } else {
            top = options.minGap;
        }
        if (options.wrapper){
            // options.wrapper = true;
            top = options.minGap;
            wrapper = $('<div class="ui-dialog-wrapper">').appendTo("body").css({
                overflowX: "hidden",
                overflowY: "auto",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                bottom: 0,
            }).click(function(e){
                if (e.target === wrapper.get(0)){
                    $(elm).trigger("dialog.close");
                }
            });
            // $("body,html").css({overflow:"hidden"});
            $("html").css({overflow:"hidden"});
            $("body").css({overflowX:"scroll"});
        }

        if (top < 0) {
            top = 0;
        }


        $("body .ui-dialog").last().each(function () {
            var p = $(this).offset();
            if (p.top == top) {
                top += 20;
            }
            if (p.left == left) {
                left += 20;
            }
        });

        dialog.css({
            width: width + "px",
            height: height + "px",
            left: left + "px",
            top: top + "px",
            visibility: "hidden",
            marginBottom: options.minGap
        });
        if (options.wrapper){
            dialog.appendTo(wrapper);
        } else {
            dialog.appendTo("body");
        }

        if (options.grow) {
            dialog.css({height: "auto", minHeight: height + "px"});
        }

        function windowResize() {
            if (options.modal) {
                overlay.css({width: $window.width() + "px", height: $document.height() + "px"});
            }
//            dialog.css({left: (($window.width() - width) / 2) + "px"});
        }

        function documentKeydown(e) {
            if (options.overlayClose && e.which == 27) {
                $(".ui-dialog:last").children().trigger("dialog.close");
                e.stopImmediatePropagation();
            }
        }


        dialog.append(elm);
        setTimeout(function () {
            var dHeight = dialog.height();
            if (windowHeight > dHeight && !options.wrapper) {
                top = (windowHeight - dHeight) / 2 + $window.scrollTop();
                dialog.css({
                    top: top + "px",
                    visibility: ""
                });
            } else {
                dialog.css({
                    visibility: ""
                });
            }
        }, 20);

        if (options.resize) {
            dialog.uiResize({invert: false});
        }

        dialog.find(".ui-dialog-header").onMouse({
            "mouse.start": function (e, pos) {
                pos.data.x = dialog.position().left;
                pos.data.y = dialog.position().top;
                pos.preventDefault();
                if (dialog.find(".ui-dialog-header").get(0) == e.target) {
                    pos.data.move = true;
                }
            },
            "mouse.move": function (e, pos) {
                var diff = pos.diff();
                if (pos.data.move) {
                    var t1 = pos.data.y + diff.y;
                    if (t1 < 0) {
                        t1 = 0;
                    }
                    dialog.css({left: pos.data.x + diff.x + "px", top: t1 + "px"});
                }
            }
        });
        $(window).on("resize", windowResize);
        $(window).on("keydown", documentKeydown);

        windowResize();
        $(elm).bind("dialog.close", function () {
            $(window).off("resize", windowResize);
            $(window).off("keydown", documentKeydown);
            dialog.hide();
            $(elm).trigger("dialog.closed");
            if (options.wrapper){
                wrapper.destroy();
                $("body,html").css({overflow:""});
            } else {
                dialog.destroy();
            }

            if (options.modal) {
                overlay.destroy();
            }
        }).addClass("ui-dialog-content");

        $(".ui-dialog-close, .ui-dialog-cancel", dialog).bindTap().click(function () {
            $(elm).trigger("dialog.close");
            return false;
        });
    });
};
