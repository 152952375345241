var _ = require("underscore");

function getDataNumber(elm, prop, def) {
    var val = parseInt($(elm).data(prop), 10);
    return isNaN(val) ? def : val;
}

function getDataNumberFloat(elm, prop, def) {
    var val = parseFloat($(elm).data(prop), 10);
    return isNaN(val) ? def : val;
}

function getDataBol(elm, prop, def) {
    var val = $(elm).data(prop);
    return val == "true" || val == "1";
}

function Slider(elm, options) {
    options.decimals = getDataNumber(elm, "decimals", false);
    options = $.extend({
        min: options.decimals ? getDataNumberFloat(elm, "min", 0) * Math.pow(10, options.decimals) : getDataNumber(elm, "min", 0),
        max: options.decimals ? getDataNumberFloat(elm, "max", 100) * Math.pow(10, options.decimals) : getDataNumber(elm, "max", 100),
        leftVal: options.decimals ? getDataNumberFloat(elm, "leftval", 0) * Math.pow(10, options.decimals) : getDataNumber(elm, "leftval", 0),
        rightVal: options.decimals ? getDataNumberFloat(elm, "rightval", 0) * Math.pow(10, options.decimals) : getDataNumber(elm, "rightval", 0),
        speed: 0.5,
        unit: "",
        ruler: getDataBol(elm, "ruler", false),
        moveRight: getDataBol(elm, "moveright", false),
        inputs: getDataBol(elm, "inputs", false)
    }, options);
    var $elm = $(elm), $content = $elm.find(".ui-slider-content"),
        $left = $elm.find(".ui-slider-left"),
        $right = $elm.find(".ui-slider-right"),
        $ticks = $elm.find(".ui-slider-tick"),
        $holder = $elm.find(".ui-slider-holder"),
        width = 0;

    if ($holder.length > 0) {
        width = $holder.width();
    }
    if ($elm.data("unit")) {
        options.unit = $elm.data("unit");
    }
    $elm.addClass("ui-slider-v2");

    function checkDecimals(num, type) {
        return options.decimals ? num / Math.pow(10, options.decimals) : num;
    }

    if (options.ruler) {
        var $ruler = $('<div class="ui-slider-ruler"></div>');
        var half = width / 2;
        $ruler.append('<div class="ui-slider-ruler-mark first">' + getValue(0) + '</div>');
        $ruler.append('<div class="ui-slider-ruler-mark second">' + checkDecimals(getValue(half / 2)) + '</div>');
        $ruler.append('<div class="ui-slider-ruler-mark middle">' + checkDecimals(getValue(half)) + '</div>');
        $ruler.append('<div class="ui-slider-ruler-mark forth">' + checkDecimals(getValue(half + half / 2)) + '</div>');
        $ruler.append('<div class="ui-slider-ruler-mark last">' + checkDecimals(getValue(width)) + '</div>');

        $elm.append($ruler);
    }

    if (options.inputs) {
        var $inputs = $('<div class="ui-slider-inputs"></div>');
        $inputs.append('<div class="input-min"><input type="text" value="' + ($left.data("val") || $elm.data("min")) + '">' + (options.unit ? '<span class="unit">' + options.unit + '</span>' : '') + '</div>');
        $inputs.append('<div class="input-max"><input type="text" value="' + ($right.data("val") || $elm.data("max")) + '">' + (options.unit ? '<span class="unit">' + options.unit + '</span>' : '') + '</div>');
        $elm.append($inputs);
        $inputs.on({
            "mousedown": function (e) {
                e.stopPropagation();
            }
        });
        var $minInput = $(".input-min input", $inputs);
        var $maxInput = $(".input-max input", $inputs);
        $left.on({
            "label.change": function (e, val) {
                if ($(this).data("val")) {
                    $minInput.val($(this).data("val"));
                }
            }
        });
        $right.on({
            "label.change": function (e, val) {
                if ($(this).data("val")) {
                    $maxInput.val($(this).data("val"));
                }
            }
        });
        $minInput.change(function (e) {
            var num = Number($(this).val());
            if (isNaN(num) || num < options.min) {
                num = options.min
            }
            if (num > $right.data("val")) {
                $maxInput.val(num);
                num = $right.data("val");
                $maxInput.trigger("change");
            }
            $(this).val(num);
            $left.data("val", num);
            $elm.trigger("slider.change").trigger("updateUi");
        });
        $maxInput.change(function (e) {
            var num = Number($(this).val());
            if (isNaN(num) || num > options.max) {
                num = options.max
            }
            if (num < $left.data("val")) {
                $minInput.val(num);
                num = $left.data("val");
                $minInput.trigger("change");
            }
            $(this).val(num);
            $right.data("val", num);
            $elm.trigger("slider.change").trigger("updateUi");
        })
    }

    function getValue(l1) {
        return options.min + Math.round(l1 / width * (options.max - options.min));
    }

    function boundLeft(l1) {
        if (l1 < 0) {
            return 0;
        }
        if ($right.length) {
            var l2 = $right.position().left;
            if (l1 > l2) {
                return l2;
            }
        }
        if (l1 > width) {
            return width;
        }
        return l1;
    }

    function getSnapX(l1) {
        var left, a1, a2 = false, tick;
        $ticks.each(function () {
            a1 = Math.abs($(this).position().left - l1);
            if (a2 === false || a1 < a2) {
                tick = this;
                left = $(this).position().left;
                a2 = a1;
            }
        });
        if ($ticks.length == 0) {
            return {left: (getValue(l1) - options.min) / (options.max - options.min) * width}
        }
        return {left: left};
    }

    function getSnap($handle) {
        return $.extend({tick: $handle}, getSnapX($handle.position().left));
    }

    function boundRight(l1) {
        if (l1 > width) {
            return width;
        }
        if ($left.length) {
            var l2 = $left.position().left;
            if (l1 < l2) {
                return l2;
            }
        }
        if (l1 < 0) {
            return 0;
        }

        return l1;
    }

    function update() {
        var $holder = $elm.find(".ui-slider-holder");
        if ($holder.length === 0) {
            return;
        }
        try {
            width = $holder.width();
        } catch (e) {
            return;
        }
        var valLeft = options.decimals ? getDataNumberFloat($left, "val", options.min) * Math.pow(10, options.decimals) : getDataNumber($left, "val", options.min),
            left = (valLeft - options.min) / (options.max - options.min) * width,
            valRight = options.decimals ? getDataNumberFloat($right, "val", options.max) * Math.pow(10, options.decimals) : getDataNumber($right, "val", options.max),
            right = (valRight - options.min) / (options.max - options.min) * width;
        var val;
        if ($left.length) {
            $left.css({left: left + "px"});
            val = checkDecimals(Math.round(valLeft));
            $left.trigger("label.change").find(".ui-slider-label").text(val + options.unit);
        }
        if ($right.length) {
            $right.css({left: right + "px"});
            val = checkDecimals(Math.round(valRight));
            $right.trigger("label.change").find(".ui-slider-label").text(val + options.unit);
        }
        if ($content.length) {
            $content.css({left: left + "px", right: width - right + "px"});
        }
        $ticks.each(function () {
            var val1 = options.decimals ? getDataNumberFloat(this, "val", options.min) * Math.pow(10, options.decimals) : getDataNumber(this, "val", options.min),
                val2 = (val1 - options.min) / (options.max - options.min) * width;
            $(this).css({left: val2 + "px"});
            val = checkDecimals(Math.round(val1));
            $(this).find(".ui-slider-label").trigger("label.change").text(val + options.unit);
        });
    }

    $elm.on({
        mousedown: function (e) {
            if ($(e.target).closest(".ui-slider-right, .ui-slider-left").size() > 0) {
                return;
            }
            var left = e.pageX - $elm.find(".ui-slider-holder").offset().left, snap;
            if ($left.length && !options.moveRight) {
                snap = getSnapX(left);
                left = snap.left;
                TweenLite.to($left, options.speed, {css: {left: left + "px"}, ease: Power2.easeOut});
                if ($content.length) {
                    TweenLite.to($content, options.speed, {css: {left: left + "px"}, ease: Power2.easeOut});
                }
                setValue($left, getValue(left));
            } else if ($right.length) {
                snap = getSnapX(left);
                left = snap.left;
                TweenLite.to($right, options.speed, {css: {left: left + "px"}, ease: Power2.easeOut});
                if ($content.length) {
                    TweenLite.to($content, options.speed, {css: {right: width - left + "px"}, ease: Power2.easeOut});
                }
                setValue($right, getValue(left));
            }

        }
    });


    $elm.addClass("destroy").on({
        updateUi: function (e) {
            e.stopPropagation();
            update();
        },
        destroy: function () {
            $(window).off({resize: resize});
        }
    });

    function resize() {
        update();
    }

    update();

    $(window).on({
        resize: resize
    });

    if ($elm.is(":hidden")) {
        $elm.addClass("show");
        $elm.on({
            show: function () {
                if ($elm.is(":hidden")) {
                    return;
                }
                $elm.removeClass("show");
                update();
            }
        })
    }


    var changeTm;

    function triggerDelayChange($node, val) {
        if (changeTm) {
            clearTimeout(changeTm);
        }
        changeTm = setTimeout(function () {
            $node.trigger("slider.change", [val]);
        }, 200);
    }


    function setValue($node, val, noTrigger) {
        if ($node.data("val") !== val) {
            if (options.decimals) {
                val = Math.round(val) / Math.pow(10, options.decimals);
            }
            $node.data("val", val);
            $node.trigger("label.change").find(".ui-slider-label").text(val + options.unit);
            if (!noTrigger) {
                triggerDelayChange($node, val);
            }
        }
    }

    $left.onMouse({
        "mouse.start": function (e, pos) {
            pos.preventDefault();
            pos.data.left = $left.position().left;
        },
        "mouse.move": function (e, pos) {
            var diff = pos.diff();
            var l1 = boundLeft(pos.data.left + diff.x);
            $left.css({left: l1 + "px"});
            setValue($left, getValue(l1));
            if ($content.length) {
                $content.css({left: l1 + "px"});
            }
        },
        "mouse.stop": function () {
            var snap = getSnap($left);
            if (snap.tick) {
                setValue($left, getValue(snap.left));
                TweenLite.to(this, options.speed, {css: {left: snap.left + "px"}, ease: Power2.easeIn});
                if ($content.length) {
                    TweenLite.to($content, options.speed, {css: {left: snap.left + "px"}, ease: Power2.easeIn});
                }
            }
        }
    });
    $right.onMouse({
        "mouse.start": function (e, pos) {
            pos.data.left = $right.position().left;
            pos.preventDefault();

        },
        "mouse.move": function (e, pos) {
            var diff = pos.diff();
            var l1 = boundRight(pos.data.left + diff.x);
            $right.css({left: l1 + "px"});
            setValue($right, getValue(l1));
            if ($content.length) {
                $content.css({right: width - l1 + "px"});
            }
        },
        "mouse.stop": function () {
            var snap = getSnap($right);
            if (snap.tick) {
                setValue($right, getValue(snap.left));
                TweenLite.to(this, options.speed, {css: {left: snap.left + "px"}, ease: Power2.easeIn});
                if ($content.length) {
                    TweenLite.to($content, options.speed, {
                        css: {right: width - snap.left + "px"},
                        ease: Power2.easeIn
                    });
                }
            }
        }
    });

    var ui = {
        getValue: function () {
            return [Number($left.data('val') || options.min), Number($right.data('val') || options.max)]
        }
    };
    $elm.data("ui", ui);

}


$.fn.uiSlider = function (options) {
    options = $.extend({v: 1}, options);
    options.v = 2;
    return this.each(function () {
        new Slider(this, options);
    });
};